<template class="table-style">
  <v-container fluid :class="hasCallCenter ? 'call-center-margin' : ''">
    <v-dialog v-model="isActive" max-width="600" scrollable>
      <template #activator="{ on }">
        <v-btn v-if="row.pricingLog" class="btn-secondaryaction" v-on="on">
          View Pricing Logs
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Pricing Logs
        </v-card-title>

        <v-card-text style="white-space: pre-wrap">
          {{ row.pricingLog }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click="isActive = false">Dismiss</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout column sheet>
      <h2>Bid {{ row.bidId }} Change Log</h2>
      <DataTable
        ref="dataTable"
        v-bind="tableProps"
        style="margin-top: -15px; z-index=0;"
      />
    </v-layout>
  </v-container>
</template>

<script>
import bids from '@/services/bids'
import DataTable from '@/components/DataTable.vue'
import { deepClone } from '@/utils/deepClone'
import { calculatedValues, datePredefined, textLike } from '@/utils/predefined'
import { DateTime } from 'luxon'
import * as logger from '@/utils/logger'
import { authComputed } from '@/state/helpers'
import GenericActionableColumn from '@/components/GenericActionableColumn.vue'

export default {
  components: {
    DataTable,
  },
  metaInfo() {
    return {
      title: 'Reservation Logs',
    }
  },
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    props: {
      type: Object,
      default: () => ({}),
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isActive: false,
      debounce: undefined,
      filters: () => [],
      sorts: () => [],
      categoryParentId: undefined,
      selectedCategory: undefined,
      itemsPerPage: 10,
      page: 1,
      tableProps: {
        enableExport: false,
        enableStatusFilterToggle: true,
        tableId: 'bid_audits_table_view',
        total: 0,
        currentPage: 1,
        perPage: 10,
        list: [],
        sort: this.sort,
        changePage: this.changePage,
        addFilter: this.addFilter,
        removeFilter: this.removeFilter,
        setSort: this.setSort,
        isDetailed: false,
        shareFilters: this.receiveFilters,
        shareSorts: this.receiveSorts,
        loading: true,
        hideActions: true,
        calculatedValues,
        columns: [
          {
            _t_id: '527872e63afc489a84f238c9b7d9ef54',
            prop: 'datetime',
            text: 'Time',
            computedText: (item) =>
              DateTime.fromISO(item).toFormat('MM/dd/yyyy HH:mm'),
            sort: false,
            filter: false,
            type: 'date',
            filterType: 'eq',
            predefined: deepClone(datePredefined),
          },
          {
            _t_id: 'b634b98c27c242658f7f3c5f02fd5a24',
            prop: ['user/firstName', 'user/lastName'],
            text: 'User',
            sort: false,
            filter: false,
            type: 'text',
            action: 'CUSTOMER_DETAIL',
            displayType: 'action-item',
            sortProp: 'user/lastName',
            filterType: 'contains',
            childMethod: 'and',
          },
          {
            _t_id: 'f2216aef96f841f0904c70aee84b94c3',
            prop: 'actionType/label',
            text: 'Action',
            sort: false,
            filter: false,
            type: 'text',
            detail: false,
            filterType: 'contains',
            predefined: textLike,
          },
          {
            _t_id: '4c999fb98dc64323a41271ef80d6fce8',
            prop: 'previousValue',
            text: 'Previous',
            sort: false,
            filter: false,
            detail: false,
            type: 'text',
            filterType: 'eq',
            defaultSort: false,
            component: GenericActionableColumn,
            displayType: 'action-type-list-previous',
          },
          {
            _t_id: '616f7a31c9134a1c841b7c0c986d9592',
            prop: 'newValue',
            text: 'New',
            sort: false,
            filter: false,
            detail: false,
            type: 'text',
            filterType: 'eq',
            defaultSort: false,
            component: GenericActionableColumn,
            displayType: 'action-type-list-new',
          },
        ],
      },
    }
  },
  computed: {
    ...authComputed,
  },
  watch: {},
  mounted() {
    this.getTable()
  },
  methods: {
    receiveFilters(filters) {
      this.filters = filters
    },
    receiveSorts(sorts) {
      this.sorts = sorts
    },
    addFilter() {
      this.currentPage = 1
      this.refresh()
    },
    removeFilter() {
      this.currentPage = 1
      this.refresh(true)
    },
    refresh(immediate) {
      if (this.debounce) {
        clearTimeout(this.debounce)
      }
      if (immediate) {
        this.getTable()
      } else {
        this.debounce = setTimeout(this.getTable, 500)
      }
    },
    async getTable() {
      const sorts = this.sorts.asQueryParams()

      this.tableProps.loading = true

      const params = {
        sorts,
        pageSize: this.itemsPerPage,
        page: this.currentPage,
        bidId: this.row.bidId,
      }
      const bidAudits = await bids.getBidAudits(params).catch((error) => {
        logger.error(error)
        this.tableProps.loading = false

        return false
      })

      bidAudits.data.resultList.forEach(function (entry, index, list) {
        if (entry.previousValue && entry.newValue) {
          list[index].previousValue = JSON.parse(entry.previousValue)
          list[index].newValue = JSON.parse(entry.newValue)
        }
      })

      if (bidAudits) {
        this.tableProps.perPage = this.itemsPerPage
        this.tableProps.currentPage = this.currentPage
        this.tableProps.list = bidAudits.data.resultList
        this.tableProps.total = bidAudits.data.count
        this.tableProps.loading = false
      }
    },
    sort(sortItem) {
      // comment line - remove later.
      this.sorts.add(sortItem)
      this.refresh(true)
    },
    changePage(pagination) {
      this.currentPage = pagination.page
      this.itemsPerPage = pagination.rowsPerPage
      this.refresh()
    },
  },
}
</script>
