<template>
  <div>
    <BaseTableWrapper
      :table-init="tableProps"
      :initial-filters="tableProps.initialFilters"
    >
      <h1 class="page-header">Marketplace Bids</h1>
    </BaseTableWrapper>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import bids from '@/services/bids'
import BaseTableWrapper from '@/components/BaseTableWrapper.vue'
import BaseActionsRequiresWrapper from '@/components/BaseActionsRequiresWrapper.vue'
import actionsWrapper from '@/utils/BaseActionsWrapper'
import { currencyFilter } from '@/utils/currency'
import { textLike } from '@/utils/predefined'
import { deepClone } from '@/utils/deepClone'
import { datePredefined } from '@/utils/predefined'
import BidAuditDetail from '@/components/BidAuditDetail.vue'
import { authComputed } from '@/state/helpers'

const wrappedBaseActions = actionsWrapper(BaseActionsRequiresWrapper, 'bid', [
  'view-reservation',
  'view-quote',
])

const columns = [
  {
    _t_id: '614fbb01',
    prop: '/',
    text: 'Actions',
    component: wrappedBaseActions,
    sort: false,
    filter: false,
    detail: false,
    type: 'actions',
  },
  {
    _t_id: '72be229a',
    prop: 'bidId',
    text: 'Bid ID',
    sort: true,
    filter: true,
    type: 'number',
    filterType: 'eq',
    sortProp: 'bidId',
    defaultSort: true,
  },
  {
    _t_id: '04888c04',
    prop: 'quoteId',
    text: 'Quote ID',
    sort: true,
    filter: true,
    type: 'number',
    filterType: 'eq',
    sortProp: 'quoteId',
  },
  {
    _t_id: 'sd6oh64evb',
    prop: 'tripId',
    text: 'Trip ID',
    sort: true,
    filter: true,
    type: 'number',
    filterType: 'eq',
    sortProp: 'tripId',
  },
  {
    _t_id: '3wer45211',
    prop: 'marketName',
    text: 'Market',
    sort: true,
    filter: true,
    type: 'text',
    sortProp: 'marketName',
    filterProp: 'marketName',
    filterType: 'contains',
  },
  {
    _t_id: '3we5346312',
    prop: 'operatorMarketName',
    text: 'Operator Market',
    sort: true,
    filter: true,
    type: 'text',
    filterType: 'contains',
  },
  {
    _t_id: '322356dsge13',
    prop: 'operatorName',
    text: 'Operator',
    sort: true,
    filter: true,
    type: 'text',
    filterType: 'contains',
    childMethod: 'and',
  },
  {
    _t_id: '19a1d601',
    prop: 'bidAmount',
    text: 'Bid Amount',
    detail: false,
    sort: true,
    filter: true,
    type: 'number',
    filterType: 'eq',
    sortProp: 'bidAmount',
    computedText: (item) => currencyFilter(item),
  },
  {
    _t_id: '0242ac12',
    prop: 'bidVehiclesString',
    text: 'Vehicle Types',
    filter: true,
    detail: false,
    type: 'text',
    computedText: (item) =>
      item
        .split('::')
        .map((i) => i.split(',')[0])
        .join(', '),
    filterType: 'contains',
    childMethod: 'or',
    predefined: textLike,
  },
  {
    _t_id: 'ww3543sd2',
    prop: 'bidVehiclesString',
    text: 'Vehicle Counts',
    detail: false,
    type: 'text',
    computedText: (item) =>
      item
        .split('::')
        .map((i) => i.split(',')[1])
        .join(', '),
    filterType: 'contains',
    childMethod: 'or',
    predefined: textLike,
  },
  {
    _t_id: 'fkq10mjyx2',
    prop: 'bidPassengerCount',
    text: 'Passenger Count',
    sort: true,
    filter: true,
    type: 'number',
    filterType: 'eq',
    sortProp: 'bidPassengerCount',
  },
  {
    _t_id: '0488123',
    prop: 'isConverted',
    text: 'Selected',
    sort: true,
    type: 'text',
    sortProp: 'isConverted',
    computedText: (item) => (item ? 'Yes' : 'No'),
  },
  {
    _t_id: '04888984',
    prop: 'soldOut',
    text: 'Sold out',
    sort: true,
    type: 'text',
    sortProp: 'soldOut',
    computedText: (item) => (item ? 'Yes' : 'No'),
  },
  {
    _t_id: '30k5ebjkv3',
    prop: 'active',
    text: 'Active',
    sort: true,
    type: 'text',
    sortProp: 'active',
    computedText: (item) => (item ? 'Yes' : 'No'),
  },
  {
    _t_id: 'f4dfd978',
    prop: 'updatedOn',
    text: 'Last Modified',
    computedText: (item) =>
      item === null ? '' : DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
    sort: true,
    filter: true,
    type: 'date',
    method: 'and',
    childMethod: 'and',
    filterType: 'eq',
    predefined: deepClone(datePredefined),
  },
  {
    _t_id: '928a14ff',
    prop: 'isPricedByPricingEngine',
    text: 'Is Priced By Pricing Engine',
    sort: true,
    filter: true,
    type: 'text',
    computedText: (item) => (item ? 'Yes' : 'No'),
    filterType: 'eq',
    customFilterTabDisplay: (val) => {
      return val ? 'Yes' : 'No'
    },
    predefined: [
      {
        text: 'Is Priced By Pricing Engine',
        controlType: 'default-repeat',
        refreshOnSelect: true,
        withValue: true,
        checkbox: true,
        value: 1,
        customEval: (activeFilter) => {
          if (!activeFilter.value) {
            activeFilter.value = true
          } else {
            activeFilter.value = undefined
          }
        },
      },
    ],
  },
  {
    _t_id: '3944057c',
    prop: 'clientAmount',
    text: 'Client Amount',
    sort: true,
    filter: true,
    type: 'number',
    filterType: 'eq',
    computedText: (item) => currencyFilter(item),
  },
  {
    _t_id: 'e4b81f95',
    prop: 'floorMarkupAmount',
    text: 'Floor Markup Amount',
    sort: true,
    filter: true,
    type: 'number',
    filterType: 'eq',
    computedText: (item) => currencyFilter(item),
    defaultHidden: true,
  },
  {
    _t_id: '0267a2e2',
    prop: 'markupAmount',
    text: 'Markup Amount',
    sort: true,
    filter: true,
    type: 'number',
    filterType: 'eq',
    computedText: (item) => currencyFilter(item),
    defaultHidden: true,
  },
  {
    _t_id: '5779b1a3',
    prop: 'discountAmount',
    text: 'Discount Amount',
    sort: true,
    filter: true,
    type: 'number',
    filterType: 'eq',
    computedText: (item) => currencyFilter(item),
    defaultHidden: true,
  },
  {
    _t_id: 'b1bb3aef',
    prop: 'feeAmount',
    text: 'Fee Amount',
    sort: true,
    filter: true,
    type: 'number',
    filterType: 'eq',
    computedText: (item) => currencyFilter(item),
    defaultHidden: true,
  },
  {
    _t_id: '46a95ab3',
    prop: 'lowestConverted',
    text: 'Lowest Converted',
    sort: true,
    filter: true,
    type: 'text',
    computedText: (item) => (item ? 'Yes' : 'No'),
    defaultHidden: true,
    filterType: 'eq',
    customFilterTabDisplay: (val) => {
      return val ? 'Yes' : 'No'
    },
    predefined: [
      {
        text: 'Lowest Converted',
        controlType: 'default-repeat',
        refreshOnSelect: true,
        withValue: true,
        checkbox: true,
        value: 1,
        customEval: (activeFilter) => {
          if (!activeFilter.value) {
            activeFilter.value = true
          } else {
            activeFilter.value = undefined
          }
        },
      },
    ],
  },
  {
    _t_id: '6f10124a',
    prop: 'lowestBid',
    text: 'Lowest Bid',
    sort: true,
    filter: true,
    type: 'text',
    computedText: (item) => (item ? 'Yes' : 'No'),
    defaultHidden: true,
    filterType: 'eq',
    customFilterTabDisplay: (val) => {
      return val ? 'Yes' : 'No'
    },
    predefined: [
      {
        text: 'Lowest Bid',
        controlType: 'default-repeat',
        refreshOnSelect: true,
        withValue: true,
        checkbox: true,
        value: 1,
        customEval: (activeFilter) => {
          if (!activeFilter.value) {
            activeFilter.value = true
          } else {
            activeFilter.value = undefined
          }
        },
      },
    ],
  },
  {
    _t_id: 'cd3ef7e0',
    prop: 'unavailabilityReason',
    text: 'Unavailability Reason',
    sort: true,
    filter: true,
    type: 'text',
    filterType: 'contains',
    defaultHidden: true,
    childMethod: 'and',
  },
  {
    _t_id: 'fc245e3f',
    prop: 'filterOverride',
    text: 'Filter Override',
    sort: true,
    filter: true,
    type: 'text',
    computedText: (item) => (item ? 'Yes' : 'No'),
    defaultHidden: true,
    filterType: 'eq',
    customFilterTabDisplay: (val) => {
      return val ? 'Yes' : 'No'
    },
    predefined: [
      {
        text: 'Filter Override',
        controlType: 'default-repeat',
        refreshOnSelect: true,
        withValue: true,
        checkbox: true,
        value: 1,
        customEval: (activeFilter) => {
          if (!activeFilter.value) {
            activeFilter.value = true
          } else {
            activeFilter.value = undefined
          }
        },
      },
    ],
  },
]

export default {
  components: {
    BaseTableWrapper,
  },
  props: {
    isMarketplace: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isAdmin: false,
      tableProps: {
        enableExport: true,
        exportName: 'marketplace_bids.csv',
        exportServiceAction: (params) => bids.exportMarketBids(params),
        addNewEnabled: false,
        enableStatusFilterToggle: false,
        enableSavedViews: false,
        tableId: 'marketplace-bids-tv-view',
        initialFilters: [],
        currentPage: 1,
        perPage: 10,
        isDetailed: true,
        detailKeyId: 'bidId',
        detail: BidAuditDetail,
        columns,
        collection: 'Bids',
        enableColumnConfig: true,
        action: (params) => bids.getMarketBids(params),
      },
    }
  },
  computed: {
    ...authComputed,
  },
  mounted() {
    columns.push(
      {
        _t_id: '09fgp40v2i',
        prop: 'takeRate',
        text: 'Take Rate',
        sort: true,
        filter: true,
        type: 'number',
        filterType: 'eq',
        sortProp: 'takeRate',
      },
      {
        _t_id: '4s6dyek4bm',
        prop: 'markup',
        text: 'Markup',
        sort: true,
        filter: true,
        type: 'number',
        filterType: 'eq',
        sortProp: 'markup',
      }
    )
  },
}
</script>
